<template>
  <Layout
    top-bar-title="Reclass Note Line"
    :sections="sections"
    :baseLayoutInfoUrl="'/base-layout-info/'"
  >
    <v-container class="py-0" fluid>
      <v-card elevation="3" class="mb-3" :loading="localLoading">
        <v-card-title v-text="'Select Customer Activity'" />
        <v-card-text class="pt-0 pb-3">
          <v-row>
            <v-col class="py-0">
              <CrudTable
                v-bind="crudTableConfig"
                :storeModule="storeModule"
                :customOptions="filtersTable"
                :activeSingleSelect="true"
                :showActionsPanel="false"
                :adaptableHeight="true"
                :footerHeight="240"
                @modelEmitter="onSelectItem"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-card elevation="3" class="mb-3" :loading="localLoading">
        <v-card-title class="d-flex justify-space-between">
          <span>Allocated Fields</span>
        </v-card-title>
        <v-card-text class="pb-0">
          <v-row>
            <v-col cols="3">
              <v-text-field
                :loading="localLoading"
                v-model="allocated"
                suffix="€"
                label="Allocated Amount"
                :rules="[AllocatedFieldValidation]"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-row>
        <v-col class="d-flex justify-end py-0">
          <v-btn
            elevation="3"
            class="mr-3"
            v-text="'Cancel'"
            @click="$router.back()"
          />
          <v-btn
            elevation="3"
            color="primary"
            v-text="'Save'"
            :disabled="localLoading"
            :loading="localLoading"
            @click="saveBtn"
          />
        </v-col>
      </v-row>
    </v-container>
  </Layout>
</template>

<script>
import Layout from '@/apps/core/components/Layout'
import sections from '@/apps/dido/views/defaultNavigationDrawerSections'
import CrudTable from '@/lib/uncrudtable/components/CrudTable'
import apiClient from '@/lib/unlogin/store/apiclient'
import { createWarningNotification } from '@/lib/unnotificationsqueue'

export default {
  name: 'ReclassNoteLine',
  components: { Layout, CrudTable },
  data () {
    return {
      sections: sections,
      storeModule: 'promoDetachmentsForNotes',
      noteLinePreselectedData: null,
      selectedPromoDetachment: null,
      allocated: 0,
      localLoading: false
    }
  },

  created () {
    this.getNoteLineData(this.noteLineId)
  },

  computed: {
    crudTableConfig () {
      return this.$store.getters[`${this.storeModule}/getCrudTableConfig`]
    },

    noteLineId () {
      return +this.$route.query?.note_line_id
    },

    noteId () {
      return +this.$route.query?.note_id
    },

    filtersTable () {
      return {
        filterFields: {
          note_id: !this.noteId ? [] : [this.noteId]
        }
      }
    }
  },

  methods: {
    async getNoteLineData (id) {
      try {
        this.localLoading = true
        const note = await apiClient.get(`/note-lines/${id}/`)
        this.noteLinePreselectedData = note.data
        this.allocated = this.noteLinePreselectedData.allocated
      } catch (error) {
        console.error(error)
      } finally {
        this.localLoading = false
      }
    },

    async saveBtn () {
      try {
        if (!this.selectedPromoDetachment) {
          await this.$store.dispatch(
            'addNotification',
            createWarningNotification("Select a 'Customer Activity' before saving"))
        }

        if (this.AllocatedFieldValidation(this.allocated) !== true) {
          await this.$store.dispatch(
            'addNotification',
            createWarningNotification(this.AllocatedFieldValidation(this.allocated)))
        }

        this.localLoading = true

        const body = {
          action_name: 'change_note_line_promo_detachment',
          list_ids: [this.noteLineId],
          extra_args: {
            promo_detachment_id: this.selectedPromoDetachment.id,
            allocated: +this.allocated
          }
        }

        await apiClient.post('/promo-note-validation-actions/', body)
        this.$router.back()
      } catch (err) {
        console.error(err)
      } finally {
        this.localLoading = false
      }
    },

    onSelectItem (itemList) {
      this.selectedPromoDetachment = itemList[0] || null
    },

    AllocatedFieldValidation (value) {
      if (isNaN(value)) return "Only numbers at 'Allocated Amount'"
      if (!value && value !== 0) return "Assign an 'Allocated Amount' before saving"
      return true
    }
  }

}
</script>

<style lang="scss" scoped>
</style>
